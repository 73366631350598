import wcuCardStyles from "./whyChooseUsCard.module.css";

export const WhyChooseUsCard = ({ title, details, icon }) => {
  return (
    <div className={wcuCardStyles.card_section}>
      <img src={icon} alt={details} className={` ${wcuCardStyles.icon} img-fluig`} />
      <h3 className={wcuCardStyles.title}> {title} </h3>
      <p className={wcuCardStyles.details}> {details} </p>
    </div>
  );
};
