import { NavBar } from "../../components/layout/navbar/navbar";
import CapitalStyles from "./capital.module.css";
import JourneyImage from "../../assets/images/firstimage.png";
import FirstImg from "../../assets/images/Mask1.png";
import SecondImg from "../../assets/images/Mask2.png";
import FastImg1 from "../../assets/images/fast13.png";
import FastImg2 from "../../assets/images/fast14.png";
import Overview1 from "../../assets/images/overview1.png";
import Overview2 from "../../assets/images/overview2.png";
import Overview3 from "../../assets/images/overview3.png";
import Invest1 from "../../assets/images/invest1.png";
import InvestIcon from "../../assets/images/investicon.svg";
import Zigzag from "../../assets/images/zizzag.svg";
import HomeIcon from "../../assets/images/homeicon.svg";
import PeopleIcon from "../../assets/images/peopleicon.svg";
import StrategyImg from "../../assets/images/strate.png";
import StrategyImg2 from "../../assets/images/strateg.png";
import Mallicon from "../../assets/images/mallicon.svg";
import MallImg2 from "../../assets/images/mallimg.png";
import Dealmainimg from "../../assets/images/dealmain.png";
import SliderImage1 from "../../assets/images/slider1.png";
import SliderImage2 from "../../assets/images/slider2.png";
import SliderImage3 from "../../assets/images/slider3.png";
import SliderImage4 from "../../assets/images/slider4.png";
import { Link, useNavigate } from "react-router-dom";
import React, { useRef, useCallback, useEffect, useState } from "react";
import { urlConfig } from "../../config/urlConfig";
import { Next } from "react-bootstrap/esm/PageItem";

const Capital = () => {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [videoBgUrl, setVideoBgUrl] = useState("");

  useEffect(() => {
    getBgVideo();
  }, []);
  

  const getBgVideo = async () => {
    const response = await fetch(
      `${urlConfig.BASE_URL}/api/platform-video?populate=*`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      console.log("An error occured while trying to get all properties");
      return;
    }

    const data = await response.json();

    setVideoBgUrl(data.data.attributes.videoBackground.data.attributes.url);
  };

  return (
    <>
      <header className={CapitalStyles.hero_section}>
        <NavBar />
      </header>
      <main>
        <div className="container">
          <section className={CapitalStyles.hero_text_section}>
            <div className={`row h-100`}>
              <div className={`col-lg-9  `}>
                <div className={CapitalStyles.text_container}>
                  <h3 className={CapitalStyles.title}>
                    Delivering Value in a Volatile World.
                  </h3>
                </div>
              </div>
            </div>
          </section>

          <div className={CapitalStyles.image_container}>
            <img
              src={JourneyImage}
              alt="Mosun Homes and Realtors Limited we are a team of experts"
              className={`${CapitalStyles.overlapPicture} img-fluid`}
            />
          </div>
        </div>


        <section className={`${CapitalStyles.about_section}`}>
          <div className={CapitalStyles.aboutContainer}>
            <div className={CapitalStyles.content_wrapper}>
              <div className={CapitalStyles.left_content}>
                <h4 className={CapitalStyles.titlea}>About us</h4>
              </div>
              <div className={CapitalStyles.right_content}>
                <p className={CapitalStyles.details}>
                  We seek to opportunistically invest in prime locations to take
                  advantage of market dislocation, information asymmetry,
                  under-managed and under-capitalised opportunities in the real
                  estate sector.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={CapitalStyles.imagesection}>
          <div className={CapitalStyles.aboutImageContainer}>
            <div className={CapitalStyles.firstImage}>
            <img className={`${CapitalStyles.imgFluid1} img-fluid`}
              src={FirstImg}
              alt="Mosun Homes and Realtors Limited we are a team of experts"
            />
            </div>
            <div className={CapitalStyles.secondImage}>
            <img className={`${CapitalStyles.imgFluid2} img-fluid`}
              src={SecondImg}
              alt="Mosun Homes and Realtors Limited we are a team of experts"
            />
            </div>
          </div>
        </section>

        <div className="container">
          <section className={CapitalStyles.hero_text_section}>
            <div className={`row h-50`}>
              <div className={`col-lg-9  `}>
                <div className={CapitalStyles.text_container2}>
                  <h4 className={CapitalStyles.title2}>
                  Fast-Growing Alternative Asset Manager
                  </h4>
                  <div className={CapitalStyles.details_container}>
                    <p className={CapitalStyles.details2}>
                    We are asset owners with significant operating experience that seeks to develop and acquire attractive assets and businesses.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className={CapitalStyles.assetContainer}>
            <div className={CapitalStyles.assetimageContainer}>
              <img src={FastImg1} alt="Building 1" className={`${CapitalStyles.fastImage1} img-fluid`} />
            </div>
            <div className={CapitalStyles.assetContainer2}>
              <div className={`col-lg-6 ${CapitalStyles.assetContainerMain}`}>
                <div className={`col-lg-6 col-md-6 col-12 ${CapitalStyles.asset_list}`}>
                  <div className={CapitalStyles.asset_name}>₦4b</div>
                  <div className={CapitalStyles.asset_value}>Assets Under Management</div>
                </div>
                <div className={`col-lg-6 col-md-6 col-12 ${CapitalStyles.asset_list}`}>
                  <div className={CapitalStyles.asset_name}>7</div>
                  <div className={CapitalStyles.asset_value}>Operating Employees</div>
                </div>
                <div className={`col-lg-6 col-md-6 col-12 ${CapitalStyles.asset_list}`}>
                  <div className={CapitalStyles.asset_name}>3</div>
                  <div className={CapitalStyles.asset_value}>Investment Professionals</div>
                </div>
                <div className={`col-lg-6 col-md-6 col-12 ${CapitalStyles.asset_list}`}>
                  <div className={CapitalStyles.asset_name}>₦915M</div>
                  <div className={CapitalStyles.asset_value}>Capital raised in 2023</div>
                </div>
              </div>
              <img src={FastImg2} alt="Building 2" className={`${CapitalStyles.fastImage2} img-fluid`} />
            </div>
          </div>  
        </div>

        <section className={CapitalStyles.why_section}>
          <div className="container">
            <div className={CapitalStyles.header_section}>
              <h2 className={`${CapitalStyles.title} section_header_title`}>
              Current Portfolio Overview
              </h2>
            </div>
            <div className={CapitalStyles.box_container}>
              <div class={`col-lg-12 ${CapitalStyles.box}`}>
                <img src={Overview1} alt="Overview 1" className={`${CapitalStyles.overview1} img-fluid`} />
                <div className={`col-lg-12 ${CapitalStyles.boximage}`}>
                  <div className={`${CapitalStyles.boxprice}`}>
                    <h2 class="price">₦2.5B</h2>
                    <p class="box-text">Real Estate</p>
                  </div>
                </div>
              </div>
              <div class={`col-lg-12 ${CapitalStyles.box}`}>
                <img src={Overview2} alt="Overview 2" className={`${CapitalStyles.overview2} img-fluid`} />
                <div className={`col-lg-12 ${CapitalStyles.boximage}`}>
                  <div className={`${CapitalStyles.boxprice}`}>
                    <h2 class="price">₦500M</h2>
                    <p class="box-text">Infrastructure</p>
                  </div>
                </div>
              </div>
              <div class={`col-lg-12 ${CapitalStyles.box}`}>
                <img src={Overview3} alt="Overview 3" className={`${CapitalStyles.overview3} img-fluid`} />
                <div className={`col-lg-12 ${CapitalStyles.boximage}`}>
                  <div className={`${CapitalStyles.boxprice}`}>
                    <h2 class="price">₦500M</h2>
                    <p class="box-text">Private Equity</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={CapitalStyles.invest_section}>
          <div className={`col-lg-12 ${CapitalStyles.investimage}`}>
            <img src={Invest1} alt="investment 1" className={`${CapitalStyles.invest1} img-fluid`} />
          </div>
          <div className={CapitalStyles.header_section}>
            <h2 className={`${CapitalStyles.title3} section_header_title`}>
              Investment Environment
            </h2>
            <p className={CapitalStyles.details3}>
              We believe the current headwinds facing the Nigerian economy are creating a prime environment for opportunistic investing.
            </p>
            <div className={CapitalStyles.invest_list}>
              <div className={CapitalStyles.invest_item}>
                <img src={InvestIcon} alt="Experienced Icon" className={`${CapitalStyles.icon} img-fluid`} />
                <div className={CapitalStyles.invest_container}>
                  <p className={CapitalStyles.invest_text}>Opportunistic capital historically perform well after an economic downturn</p>
                </div>
              </div>
              <div className={CapitalStyles.invest_item}>
                <img src={InvestIcon} alt="Expertise Icon" className={`${CapitalStyles.icon} img-fluid`} />
                <div className={CapitalStyles.invest_container}>
                  <p className={CapitalStyles.invest_text}>Potential for strong revenue growth</p>
                </div>
              </div>
              <div className={CapitalStyles.invest_item}>
                <img src={InvestIcon} alt="Transparency Icon" className={`${CapitalStyles.icon} img-fluid`} />
                <div className={CapitalStyles.invest_container}>
                  <p className={CapitalStyles.invest_text}>Limited new supply</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={CapitalStyles.theme_section}>
          <div className="container">
            <div className={CapitalStyles.header_section}>
              <h2 className={`${CapitalStyles.title} section_header_title`}>
              Investment Themes
              </h2>
            </div>
          </div>
          <div className={CapitalStyles.card_container}>
            <div className={CapitalStyles.card_row}>
              <h3 className={`${CapitalStyles.card_title}`}>Data Consumption</h3>
              <div className={CapitalStyles.vertical_line}></div>
              <img src={Zigzag} alt="Zizzag vector" className={`${CapitalStyles.card_icon} img-fluid`} />
            </div>
            <div className={CapitalStyles.card_row}>
              <h3 className={`${CapitalStyles.card_title}`}>Demographics & Affordability</h3>
              <div className={CapitalStyles.vertical_line}></div>
              <img src={HomeIcon} alt="Home vector" className={`${CapitalStyles.card_icon} img-fluid`} />
            </div>
            <div className={CapitalStyles.card_row}>
              <h3 className={`${CapitalStyles.card_title}`}>The <br/>"New Normal"</h3>
              <div className={CapitalStyles.vertical_line}></div>
              <img src={PeopleIcon} alt="People Vector" className={`${CapitalStyles.card_icon} img-fluid`} />
            </div>
          </div>
        </section>

        <div className="container">
          <section className={CapitalStyles.hero_text_section}>
            <div className={`row h-50`}>
              <div className={`col-lg-9  `}>
                <div className={CapitalStyles.text_container3}>
                  <h4 className={CapitalStyles.title3}>
                  Value Creation Strategies
                  </h4>
                </div>
              </div>
            </div>
          </section>
          <div className={CapitalStyles.strategyContainer}>
            <div className={CapitalStyles.strategyimageContainer}>
              <img src={StrategyImg} alt="Strategy 1" className={`${CapitalStyles.strategyimg} img-fluid`} />
            </div>
            <div className={CapitalStyles.strategyContainer2}>
              <ul className={CapitalStyles.strategyList}>
                <li className={CapitalStyles.strategyItem}>Our opportunistic business seeks to acquire and develop well-located assets across prime locations in Nigeria.</li>
                <li className={CapitalStyles.strategyItem}>In connection with these acquisitions and developments, we build businesses and partner with value chain players to manage the underlying assets and ultimately maximise their value by instituting best-in-class management.</li>
                <li className={CapitalStyles.strategyItem}>Post acquisition and development, we also invest in the properties to improve them before selling the assets and returning capital to our Limited Partners.</li>
              </ul>
            </div>
          </div>
          <div className={CapitalStyles.strategyContainer3}>
            <img src={StrategyImg2} alt="Strategy 2" className={`${CapitalStyles.strategyimg2} img-fluid`} />  
          </div>
        </div>

        <div className="container">
          <section className={CapitalStyles.hero_text_section}>
            <div className={`row h-50`}>
              <div className={`col-lg-9  `}>
                <div className={CapitalStyles.text_container4}>
                  <h4 className={CapitalStyles.title4}>
                  Introducing Mosun Mall Dev LP
                  </h4>
                </div>
              </div>
            </div>
          </section>
          <div className={CapitalStyles.line_container}>
            <div className={CapitalStyles.line}></div>
            <p className={CapitalStyles.additional_text}>
              Mosun Mall Dev LP (the “Deal” or “MMDLP”) is our first single project real estate development deal.
            </p>
          </div>
          <div className={CapitalStyles.icon_text_container}>
            <div className={`row h-50 align-items-start justify-content-center`}>
              <div className={`col-lg-4 align-items-start`}>
                <div className={CapitalStyles.icon_text_item}>
                  <img src={Mallicon} alt="Icon 1" className={`${CapitalStyles.icon} img-fluid`} />
                  <p className={CapitalStyles.icon_text}>The investment strategy was to acquire land and develop a multi-purpose shopping mall in a prime location.</p>
                </div>
              </div>
              <div className={`col-lg-4 align-items-start ${CapitalStyles.icon_list}`}>
                <div className={CapitalStyles.icon_text_item}>
                  <img src={Mallicon} alt="Icon 2" className={`${CapitalStyles.icon} img-fluid`} />
                  <p className={CapitalStyles.icon_text}>The Manager aims to leverage its expected advantages and network to position the deal to develop a high rental grossing multi-purpose shopping mall and find suitable partners.</p>
                </div>
              </div>
              <div className={`col-lg-4 align-items-start`}>
                <div className={CapitalStyles.icon_text_item}>
                  <img src={Mallicon} alt="Icon 3" className={`${CapitalStyles.icon} img-fluid`} />
                  <p className={CapitalStyles.icon_text}>The Manager will seek investments opportunities where there is potential for enhancing ESG standards or (for new builds) implementing appropriate ESG standards throughout the investing period,</p>
                </div>
              </div>
            </div>
          </div>
          <img src={MallImg2} alt="Mall 2" className={`${CapitalStyles.mallimg} img-fluid`} />
        </div>

        <div className="container">
          <section className={CapitalStyles.hero_text_section}>
            <div className={`row h-50`}>
              <div className={`col-lg-9`}>
                <div className={CapitalStyles.text_container5}>
                  <h4 className={CapitalStyles.title5}>
                  Summary of Terms of the Deal
                  </h4>
                </div>
              </div>
            </div>
          </section>
          <div className={CapitalStyles.dealContainer1}>
            <div className={CapitalStyles.dealcard}>
              <h3 className={CapitalStyles.dealcardTitle}>The Deal Vehicle</h3>
              <p className={CapitalStyles.dealcardText}>Mosun Mall Dev LP.</p>
            </div>
            <div className={CapitalStyles.dealcard}>
              <h3 className={CapitalStyles.dealcardTitle}>Deal Manager</h3>
              <p className={CapitalStyles.dealcardText}>Mosun Homes and Realtors Limited.</p>
            </div>
            <div className={CapitalStyles.dealcard}>
              <h3 className={CapitalStyles.dealcardTitle}>Target Rental Yield</h3>
              <p className={CapitalStyles.dealcardText}>6%</p>
            </div>
            <div className={CapitalStyles.dealcard}>
              <h3 className={CapitalStyles.dealcardTitle}>Target Investment Return</h3>
              <p className={CapitalStyles.dealcardText}>The target return for the Deal is gross 80%</p>
            </div>
          </div>
          <div className={CapitalStyles.dealContainer2}>
            <div className={CapitalStyles.leftSection}>
              <div className={CapitalStyles.dealcard2}>
                <h3 className={CapitalStyles.dealcardTitle}>Deal Size</h3>
                <p className={CapitalStyles.dealcardText}>NGN 915 Million</p>
              </div>
              <div className={CapitalStyles.dealcard2}>
                <h3 className={CapitalStyles.dealcardTitle}>Investment Objective</h3>
                <p className={CapitalStyles.dealcardText}>The Deal's strategy is to acquire land and develop a multi-purpose shopping mall in a prime location. The Deal will distribute yearly rental income generated from  leasing spaces of the shopping mall.</p>
              </div>
              <div className={CapitalStyles.dealcard2}>
                <h3 className={CapitalStyles.dealcardTitle}>Term</h3>
                <p className={CapitalStyles.dealcardText}>5 years</p>
              </div>
              <div className={CapitalStyles.dealcard2}>
                <h3 className={CapitalStyles.dealcardTitle}>Management Fees</h3>
                <p className={CapitalStyles.dealcardText}>The Manager will be entitled to receive an annual management fee (the “Management Fee”) equal to 1.5% of Total Commitments.</p>
              </div>
              <div className={CapitalStyles.dealcard2}>
                <h3 className={CapitalStyles.dealcardTitle}>Incentive Fees</h3>
                <p className={CapitalStyles.dealcardText}>The Manager will be entitled to receive an exit sale performance allocation equal to 20% of each investor's ratable share of the Partnership's profits of such exit sale.</p>
              </div>
              <div className={CapitalStyles.dealcard2}>
                <h3 className={CapitalStyles.dealcardTitle}>Distributions</h3>
                <p className={CapitalStyles.dealcardText}>Rental income will distributed to partners in the ratio of their holdings.</p>
              </div>
              <div className={CapitalStyles.dealcard2}>
                <h3 className={CapitalStyles.dealcardTitle}>Establishment Costs and Professional Fees</h3>
                <p className={CapitalStyles.dealcardText}>The Deal will bear all fees, costs and expenses incurred in the connection with the establishment and launch of the Deal. The Deal will also retain professional services of a Law Firm and project manager.</p>
              </div>
            </div>
            <div className={CapitalStyles.rightSection}>
              <img src={Dealmainimg} alt="Your" className={CapitalStyles.image} />
            </div>
          </div>
        </div>
        <div className={CapitalStyles.allimagesContainer}>
          <div className={CapitalStyles.imageSlider}>
            <img src={SliderImage1} alt="Your" className={CapitalStyles.sliderimage} />
            <img src={SliderImage2} alt="Your" className={CapitalStyles.sliderimage} />
            <img src={SliderImage3} alt="Your" className={CapitalStyles.sliderimage} />
            <img src={SliderImage4} alt="Your" className={CapitalStyles.sliderimage} />
          </div>
        </div>
      </main>

      {/* <SplashScreen /> */}
    </>
  );
};

export default Capital;
