import React, { useRef, useState, useEffect } from "react";
import NavBarStyles from "./navBar.module.css";
import MosunHomesLogo from "../../../assets/images/logo/mh-logo-nav.svg";
import MenuIcon from "../../../assets/images/icons/menu.svg";
import CloseMenuIcon from "../../../assets/images/icons/close-circle.svg";
import { useLocation, Link, useNavigate } from "react-router-dom";

export const NavBar = () => {
  const mobileMenuRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const toggleMenu = () => {
    if (mobileMenuRef.current) {
      mobileMenuRef.current.classList.toggle(NavBarStyles.mobile_menu_show);
      window.setTimeout(() => {
        setNavOpen(!navOpen);
      }, 500);
    }
  };

  const menuLinks = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About us",
      link: "/about-us",
    },
    {
      name: "Properties",
      link: "/properties",
    },
    {
      name: "Mosun Capital",
      link: "/mosun-capital",
    },
    {
      name: "Contact us",
      link: "/contact-us",
    },
  ];

  return (
    <nav className={NavBarStyles.nav_container}>
      <div className="container">
        <div className={`${NavBarStyles.nav_content} row`}>
          <div className="col-lg-3 col-12 d-flex">
            <Link to="/">
              <img
                src={MosunHomesLogo}
                alt="Mosun Homes &amp; Realtor Logo"
                className="img-fluid"
              />
            </Link>

            <div
              onClick={toggleMenu}
              className={NavBarStyles.hamburger_container}
            >
              <div className={NavBarStyles.text}>Menu</div>
              <img
                src={navOpen ? CloseMenuIcon : MenuIcon}
                alt="menu icon"
                className="img-fluid"
              />
            </div>
          </div>

          <div className={`${NavBarStyles.menu_container} col-md-6`}>
            <div ref={mobileMenuRef} className={NavBarStyles.mobile_menu}>
              <ul className={NavBarStyles.menu_group}>
                {menuLinks.map(({ name, link }, index) => (
                  <li key={`${index}-${link}`}>
                    <Link
                      className={pathname === link ? NavBarStyles.active : null}
                      to={link}
                    >
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>

              <section className={NavBarStyles.follow_section}>
                <h5 className={NavBarStyles.follow_title}>Follow us</h5>
                <div className={NavBarStyles.follow_container}>
                  {/* Facebook */}
                  <div className={NavBarStyles.icon_holder}>
                    <svg
                      width="7"
                      height="13"
                      viewBox="0 0 7 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_236_5006)">
                        <path
                          d="M4.07669 11.5781V6.73939H5.70907L5.95171 4.84489H4.07669V3.63817C4.07669 3.09149 4.22901 2.71719 5.01361 2.71719H6.00779V1.02814C5.52407 0.976301 5.03784 0.95127 4.55135 0.953164C3.10848 0.953164 2.11784 1.834 2.11784 3.45102V4.84134H0.496094V6.73584H2.12138V11.5781H4.07669Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_236_5006">
                          <rect
                            width="6.25"
                            height="11.25"
                            fill="white"
                            transform="translate(0.375 0.875)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  {/* Instgrram */}
                  <div className={NavBarStyles.icon_holder}>
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_236_5010)">
                        <path
                          d="M6.72719 4.95583C5.75168 4.95583 4.9556 5.75191 4.9556 6.72742C4.9556 7.70292 5.75168 8.499 6.72719 8.499C7.70269 8.499 8.49878 7.70292 8.49878 6.72742C8.49878 5.75191 7.70269 4.95583 6.72719 4.95583ZM12.0406 6.72742C12.0406 5.9938 12.0473 5.26682 12.0061 4.53453C11.9649 3.68395 11.7708 2.92907 11.1488 2.30709C10.5255 1.68377 9.77198 1.49107 8.9214 1.44987C8.18778 1.40867 7.46081 1.41531 6.72852 1.41531C5.99489 1.41531 5.26792 1.40867 4.53563 1.44987C3.68505 1.49107 2.93017 1.6851 2.30819 2.30709C1.68487 2.9304 1.49217 3.68395 1.45097 4.53453C1.40977 5.26815 1.41641 5.99513 1.41641 6.72742C1.41641 7.45971 1.40977 8.18801 1.45097 8.9203C1.49217 9.77088 1.6862 10.5258 2.30819 11.1477C2.9315 11.7711 3.68505 11.9638 4.53563 12.005C5.26925 12.0462 5.99622 12.0395 6.72852 12.0395C7.46214 12.0395 8.18911 12.0462 8.9214 12.005C9.77198 11.9638 10.5269 11.7697 11.1488 11.1477C11.7722 10.5244 11.9649 9.77088 12.0061 8.9203C12.0486 8.18801 12.0406 7.46104 12.0406 6.72742ZM6.72719 9.45324C5.21875 9.45324 4.00136 8.23586 4.00136 6.72742C4.00136 5.21898 5.21875 4.00159 6.72719 4.00159C8.23563 4.00159 9.45301 5.21898 9.45301 6.72742C9.45301 8.23586 8.23563 9.45324 6.72719 9.45324ZM9.56465 4.52655C9.21246 4.52655 8.92805 4.24214 8.92805 3.88995C8.92805 3.53776 9.21246 3.25335 9.56465 3.25335C9.91684 3.25335 10.2013 3.53776 10.2013 3.88995C10.2014 3.97358 10.185 4.05641 10.153 4.13369C10.1211 4.21098 10.0742 4.2812 10.015 4.34033C9.9559 4.39947 9.88568 4.44636 9.80839 4.47831C9.73111 4.51027 9.64828 4.52666 9.56465 4.52655Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_236_5010">
                          <rect
                            width="11.25"
                            height="11.25"
                            fill="white"
                            transform="translate(0.875 0.875)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  {/* Twitter */}
                  <div className={NavBarStyles.icon_holder}>
                    <svg
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.6406 2.75376L5.66578 3.16899L5.24609 3.11815C3.71843 2.92324 2.38383 2.26227 1.25067 1.15217L0.696681 0.601361L0.553988 1.00811C0.251813 1.91483 0.444869 2.8724 1.0744 3.51642C1.41015 3.87233 1.33461 3.92318 0.755438 3.71133C0.553988 3.64353 0.377719 3.59269 0.360931 3.61811C0.302175 3.67743 0.503625 4.44857 0.663106 4.75363C0.881344 5.17733 1.32621 5.59256 1.81305 5.83831L2.22434 6.03321L1.73751 6.04168C1.26746 6.04168 1.25067 6.05016 1.30103 6.22811C1.46891 6.77892 2.13201 7.36363 2.87066 7.61785L3.39108 7.79581L2.93781 8.06697C2.26631 8.45678 1.4773 8.6771 0.688288 8.69405C0.310569 8.70253 0 8.73642 0 8.76184C0 8.84658 1.02404 9.32113 1.61999 9.50756C3.40786 10.0584 5.53148 9.8211 7.12629 8.88048C8.25945 8.21103 9.39261 6.88061 9.92141 5.59256C10.2068 4.90616 10.4922 3.65201 10.4922 3.05035C10.4922 2.66055 10.5174 2.6097 10.9874 2.14363C11.2644 1.87246 11.5246 1.57587 11.575 1.49113C11.6589 1.33013 11.6505 1.33013 11.2224 1.47419C10.509 1.72841 10.4083 1.69451 10.7608 1.31318C11.021 1.04201 11.3316 0.550517 11.3316 0.406458C11.3316 0.381036 11.2057 0.423406 11.063 0.499673C10.9119 0.584413 10.5761 0.711523 10.3243 0.787789L9.87105 0.931848L9.45976 0.652205C9.23313 0.499673 8.91416 0.330192 8.74629 0.279348C8.31821 0.160712 7.66349 0.17766 7.27738 0.313244C6.22816 0.694575 5.56506 1.67756 5.6406 2.75376Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  {/* Discord */}
                  <div className={NavBarStyles.icon_holder}>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_236_5017)">
                        <path
                          d="M11.7547 3.61933C10.2077 2.46171 8.72553 2.49424 8.72553 2.49424L8.57406 2.66731C10.4132 3.21906 11.2679 4.03041 11.2679 4.03041C8.63657 2.58172 5.28506 2.59212 2.5482 4.03041C2.5482 4.03041 3.43529 3.17572 5.38266 2.62398L5.27453 2.49411C5.27453 2.49411 3.80316 2.46171 2.2454 3.6192C2.2454 3.6192 0.6875 6.42112 0.6875 9.87226C0.6875 9.87226 1.59632 11.4302 3.98717 11.5059C3.98717 11.5059 4.38744 11.0299 4.71198 10.6188C3.33809 10.2077 2.81874 9.35306 2.81874 9.35306C3.68193 9.89319 4.55471 10.2312 5.6424 10.4457C7.41198 10.81 9.61316 10.4355 11.2572 9.35306C11.2572 9.35306 10.7162 10.2293 9.29901 10.6296C9.62355 11.0299 10.013 11.4951 10.013 11.4951C12.4039 11.4194 13.3126 9.86146 13.3126 9.8724C13.3124 6.42126 11.7547 3.61933 11.7547 3.61933ZM4.98239 8.86624C4.37651 8.86624 3.8789 8.3361 3.8789 7.67622C3.92318 6.09577 6.05267 6.10063 6.08588 7.67622C6.08588 8.3361 5.59893 8.86624 4.98239 8.86624ZM8.931 8.86624C8.32512 8.86624 7.82751 8.3361 7.82751 7.67622C7.87611 6.09888 9.97901 6.0982 10.0345 7.67622C10.0345 8.3361 9.54768 8.86624 8.931 8.86624Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_236_5017">
                          <rect
                            width="13.5"
                            height="13.5"
                            fill="white"
                            transform="translate(0.25 0.25)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  {/* Linkedin */}
                  <div className={NavBarStyles.icon_holder}>
                    <svg
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_236_5021)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 10.5H9.34773V6.80872C9.34773 5.84272 8.93318 5.18306 8.02064 5.18306C7.323 5.18306 6.93491 5.62826 6.75436 6.05719C6.68645 6.21101 6.69709 6.42547 6.69709 6.63994V10.5H4.06964C4.06964 10.5 4.10345 3.96139 4.06964 3.36709H6.69709V4.48665C6.85255 3.99709 7.692 3.29857 9.03164 3.29857C10.6939 3.29857 12 4.32443 12 6.53389V10.5ZM1.41273 2.47485H1.39555C0.549 2.47485 0 1.92964 0 1.23847C0 0.533925 0.565091 0 1.42882 0C2.29173 0 2.82245 0.532613 2.83909 1.23637C2.83909 1.92754 2.29173 2.47485 1.41273 2.47485ZM0.302727 3.36709H2.64164V10.5H0.302727V3.36709Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_236_5021">
                          <rect width="12" height="10.5" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className={`${NavBarStyles.btn_container} col-md-3`}>
            <button
              className="btn mh_primary_w_btn"
              onClick={() => navigate("/contact-us")}
            >
              Contact us
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
