export const faqs = [
  {
    question: "This is a possible question",
    answer:
      "Mosun Homes and Realtors Limited we are a team of experts passionate about real estate, with over a 10 years of experience in Nigerian and global markets. We specialize in investments, sales, and property management, including homeowner association management.",
  },
  {
    question: "This is a possible question",
    answer:
      "Mosun Homes and Realtors Limited we are a team of experts passionate about real estate, with over a 10 years of experience in Nigerian and global markets. We specialize in investments, sales, and property management, including homeowner association management.",
  },
  {
    question: "This is a possible question",
    answer:
      "Mosun Homes and Realtors Limited we are a team of experts passionate about real estate, with over a 10 years of experience in Nigerian and global markets. We specialize in investments, sales, and property management, including homeowner association management.",
  },
  {
    question: "This is a possible question",
    answer:
      "Mosun Homes and Realtors Limited we are a team of experts passionate about real estate, with over a 10 years of experience in Nigerian and global markets. We specialize in investments, sales, and property management, including homeowner association management.",
  },
];
