import faqStyles from "./faq.module.css";
import { Accordion } from "react-bootstrap";
import { faqs } from "../../mockData/faq";

export const Faq = () => {
  return (
    <section className={`${faqStyles.faq_section} faq mh_cream_n_masked`}>
      <div className="container">
        <div className={faqStyles.faq_container}>
          <div className="mb-5 text-center mx-5 mx-lg-0">
            <h4 className="section_header_title mb-3">
              Frequently asked questions
            </h4>
            <p className="section_header_details">
              Whatever questions you might have we are ready to answer
            </p>
          </div>

          <Accordion defaultActiveKey={0}>
            {faqs.map(({ question, answer }, index) => (
              <Accordion.Item eventKey={index} className="mb-3" key={`${index}-${answer}`}>
                <Accordion.Header className={faqStyles.accordion_header}>
                  <div className={faqStyles.question_container}>
                    <span> Q: </span>
                    <>{question}</>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className={faqStyles.answer_container}>{answer}</div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};
