import LeaseTransactionImg from "../assets/images/Lease-transaction-service.jpg";
import ProjectDevImg from "../assets/images/Project-dev-service.jpg";
import AssetImg from "../assets/images/Asset-services.jpg";
import FacilityMgtImg from "../assets/images/Facilities-mgt-service.jpg";
import InteriorDesignImg from "../assets/images/Interior-design-service.jpg";

export const whatWeDoData = [
  {
    id: 1,
    photo: LeaseTransactionImg,
    title: "Lease Transaction & Advisory",
    details:
      "We offer leasing advice to occupiers and investors in Nigeria. Our experienced team provides tailored guidance for streamlining investment portfolios, managing rent reviews, and relocating. We bring over 10 years of experience in the Nigerian and global real estate markets to help you achieve your real estate goals and create financial value. Our Lease and Advisory Services cover retail, logistics and industrial, office, and residential leasing for individuals, investors, developers, and public sector clients across Nigeria.",
  },

  {
    id: 2,
    photo: ProjectDevImg,
    title: "Project Development",
    details:
      "We offer Project & Development Services to real estate investors and occupiers worldwide. Our services cover all sectors, including new builds, offices, industrial facilities, retail spaces, and residential buildings. Our services are flexible and can be tailored to meet your needs, whether it's a one-time consultation or a global partnership. We provide specialized services in project management, building consultancy, cost consultancy, interior design, building engineering services, and capital allowances, with highly qualified staff in each area.",
  },
  {
    id: 3,
    photo: AssetImg,
    title: "Asset Services",
    details:
      "We provide sustainable solutions to improve asset users' experiences. Our Asset Services go beyond building management to optimize experiences and create smarter environments that benefit people, communities, and the planet. We tailor our services to meet unique requirements, increase revenue, and enhance client relationships through innovative and integrated services. Our Asset Services include Property Management, Operational Asset Management, Limited Partnerships, and ESG Solutions.",
  },
  {
    id: 4,
    photo: FacilityMgtImg,
    title: "Facilities Management",
    details:
      "We offer comprehensive facilities management services to ensure your real estate is always in top condition. Our services include regular maintenance, repairs, and emergency response. We work closely with clients to develop customized solutions that meet their specific needs. Our goal is to take care of everything so that you can focus on your business. We're always looking to improve our services and stay ahead of the curve. With our approach, you can trust that your real estate is in good hands.",
  },
  {
    id: 5,
    photo: InteriorDesignImg,
    title: "Interior Design",
    details:
      "Our company specializes in residential and commercial interior design in Nigeria and beyond. With years of experience, our team of experts collaborates with clients to create aesthetically pleasing and functional spaces. We work on projects of all sizes and scopes, from small-scale renovations to large commercial buildings, and always prioritize our clients' goals and preferences.",
  },
];
