import AboutStyles from "./about.module.css";
import { NavBar } from "../../components/layout/navbar/navbar";
import MusonHomesWebsiteVideo from "../../assets/videos/Mosun_website_video.webm";
import { ImageRB } from "../../components/imageRB/imageRB";
import { TeamCard } from "../../components/teamCard/teamCard";
import { Faq } from "../../components/faq/faq";
import AboutImg from "../../assets/images/about-img.png";
import { teams } from "../../mockData/team";
import React, { useEffect, useState } from "react";
import { urlConfig } from "../../config/urlConfig";

const About = () => {
  const [videoBgUrl, setVideoBgUrl] = useState("");

  useEffect(() => {
    getBgVideo();
  }, [videoBgUrl]);

  const getBgVideo = async () => {
    const response = await fetch(
      `${urlConfig.BASE_URL}/api/platform-video?populate=*`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      console.log("An error occured while trying to get all properties");
      return;
    }

    const data = await response.json();

    setVideoBgUrl(data.data.attributes.videoBackground.data.attributes.url);
  };

  return (
    <>
      <header className={AboutStyles.header_section}>
        <NavBar />
        {videoBgUrl && (
          <video autoPlay muted loop id={AboutStyles.website_video}>
            <source
              src={`${urlConfig.BASE_URL}${videoBgUrl}#t=0.1`}
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
        )}

        <div className="container d-flex justify-content-center h-100">
          <div className={AboutStyles.text_section}>
            <h3 className={AboutStyles.title}>A little Journey into Mosun</h3>
            <p className={AboutStyles.details}>
              Transforming Real Estate through Comprehensive Services and
              Sustainable Solutions
            </p>
          </div>
        </div>
      </header>

      <section className={`${AboutStyles.about_section} mh_cream_n_masked`}>
        <div className="container">
          <div className={AboutStyles.text_section}>
            <h4 className={AboutStyles.title}>About us</h4>
            <p className={AboutStyles.details}>
              Mosun Homes and Realtors Limited we are a team of experts
              passionate about real estate, with over a 10 years of experience
              in Nigerian and global markets. We specialize in investments,
              sales, and property management, including homeowner association
              management.
              <br />
              <br />
              Our knowledgeable team is always on hand to answer your questions
              about buying, selling, or leasing a property, including legal and
              credit matters. We take a hands-on approach to asset management,
              ensuring our clients' interests are aligned with ours, and
              delivering quality results.
            </p>

            <ImageRB
              styleClass={"w-100"}
              imgSrc={AboutImg}
              imgAlt={
                "Mosun Homes and Realtors Limited we are a team of experts"
              }
            />
          </div>
        </div>
      </section>

      <section className={`${AboutStyles.vision_section} mh_white_n_masked`}>
        <div className="container">
          <div className={AboutStyles.text_section}>
            <h4 className={AboutStyles.title}>Our Vision</h4>
            <p className={AboutStyles.details}>
              At Mosun Homes &amp; Realtor, we aims to be the preferred real
              estate firm that consistently provides the most creative and
              innovative services to drive value in Nigeria and around the
              world. Our drive is to prioritize transparency, innovation, and
              creativity to deliver value to clients, positioning Mosun Homes
              &amp; Realtors as a leader in the industry. And provide our
              clients with the necessary information and resources to make
              informed decisions about their real estate investments.
            </p>
          </div>

          <div className={AboutStyles.core_value_section}>
            <div className={`main_title ${AboutStyles.core_value_title}`}>
              Our core values
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className={AboutStyles.value_card}>
                  <h5 className={AboutStyles.title}>Integrity</h5>
                  <p className={AboutStyles.details}>
                    We value honesty, ethics, and fairness in all our dealings
                    with clients. We prioritize respect to maintain strong
                    relationships and exceptional customer service.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className={AboutStyles.value_card}>
                  <h5 className={AboutStyles.title}>Collaboration</h5>
                  <p className={AboutStyles.details}>
                    At Mosun Collaboration is key to our success. We work
                    closely with clients and partners to achieve shared goals
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className={AboutStyles.value_card}>
                  <h5 className={AboutStyles.title}>Creativity</h5>
                  <p className={AboutStyles.details}>
                    We prioritize creativity as a core value, exhibiting it
                    through its innovative real estate services and investment
                    opportunities, as well as cutting-edge marketing and
                    communication strategies
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className={AboutStyles.value_card}>
                  <h5 className={AboutStyles.title}>Trust</h5>
                  <p className={AboutStyles.details}>
                    We build long-term relationships based on trust and mutual
                    understanding.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className={AboutStyles.value_card}>
                  <h5 className={AboutStyles.title}>Respect</h5>
                  <p className={AboutStyles.details}>
                    We treat all clients and partners with respect and values
                    their contributions to the company's success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={AboutStyles.team_section}>
        <div className="container">
          <div className={AboutStyles.text_section}>
            <h4 className={AboutStyles.title}>Meet the Team</h4>
            <p className={AboutStyles.details}>
              At Mosun Homes, our team of experts is dedicated to delivering
              exceptional service and results for our clients.
            </p>
          </div>

          <div className="row">
            {teams.map(({ photo, name, details, title }, index) => (
              <div
                className={`col-lg-6 d-flex ${
                  index % 2 === 1 ? "justify-content-end" : ""
                }`}
                key={`${index}-${name}`}
              >
                <TeamCard
                  name={name}
                  title={title}
                  image={photo}
                  details={details}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <Faq />
    </>
  );
};

export default About;
