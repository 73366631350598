import ImageRBStyles from "./imageRB.module.css";

export const ImageRB = ({ imgSrc, imgAlt, styleClass }) => {
  return (
    <div className={`${ImageRBStyles.img_container}`}>
      <img
        src={imgSrc}
        alt={imgAlt}
        className={`${styleClass} img-fluid`}
      />
    </div>
  );
};
