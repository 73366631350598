import ViewPropertyStyles from "./viewPrroperty.module.css";
import { NavBar } from "../../components/layout/navbar/navbar";
import { PropertyCard } from "../../components/propertyCard/propertyCard";
import { propertiesData } from "../../mockData/properties";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import InteriorOneImg from "../../assets/images/interior-1.png";
import InteriorTwoImg from "../../assets/images/interior-2.png";
import InteriorThreeImg from "../../assets/images/interior-3.png";

import ExteriorOneImg from "../../assets/images/exterior-1.png";
import ExteriorTwoImg from "../../assets/images/exterior-2.png";
import ExteriorThreeImg from "../../assets/images/exterior-3.png";
import { useParams } from "react-router-dom";
import { urlConfig } from "../../config/urlConfig";
import React, { useEffect, useState } from "react";

const ViewProperty = () => {
  const { slug } = useParams();
  const [property, setProperty] = useState(null);
  const [allProperties, setAllProperties] = useState(null);

  useEffect(() => {
    getProperty();
    getAllproperties();
  }, []);

  const getProperty = async () => {
    const response = await fetch(
      `${urlConfig.BASE_URL}/api/properties/${slug}?populate=*`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      console.log("An error occured while trying to get all properties");
      return;
    }

    const { data } = await response.json();
    console.log(data);

    setProperty(data.attributes);
  };

  const getAllproperties = async () => {
    const response = await fetch(
      `${urlConfig.BASE_URL}/api/properties/?populate=*`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      console.log("An error occured while trying to get all properties");
      return;
    }

    const { data } = await response.json();
    console.log(data);

    setAllProperties(data);
  };

  return (
    <>
      <header
        className={ViewPropertyStyles.hero_section}
        style={{
          backgroundImage: `url(
            ${urlConfig.BASE_URL}${property?.previewImage?.data?.attributes?.url}
          )`,
        }}
      >
        <NavBar />
      </header>

      <section
        className={`${ViewPropertyStyles.property_details_container} mh_white_n_masked`}
      >
        <div className="container">
          <div className={ViewPropertyStyles.basic_detail}>
            <ul className={ViewPropertyStyles.detail_list_group}>
              <li className={ViewPropertyStyles.detail_list}>
                <div className={ViewPropertyStyles.name}>Price</div>
                <div className={ViewPropertyStyles.value}>
                  NGN{property?.price}
                </div>
              </li>

              <li className={ViewPropertyStyles.detail_list}>
                <div className={ViewPropertyStyles.name}>Status</div>
                <div className={ViewPropertyStyles.value}>{property?.status}</div>
              </li>

              <li className={ViewPropertyStyles.detail_list}>
                <div className={ViewPropertyStyles.name}>Size</div>
                <div className={ViewPropertyStyles.value}>{property?.size}</div>
              </li>

              <li className={ViewPropertyStyles.detail_list}>
                <div className={ViewPropertyStyles.name}>Type</div>
                <div className={ViewPropertyStyles.value}>{property?.type}</div>
              </li>

              <li className={ViewPropertyStyles.detail_list}>
                <div className={ViewPropertyStyles.name}>Location</div>
                <div className={ViewPropertyStyles.value}>
                  {property?.location}
                </div>
              </li>
            </ul>
          </div>

          <div className={`${ViewPropertyStyles.property_summary} row`}>
            <div className={`col-lg-6 d-flex align-items-center`}>
              <div className={`row ${ViewPropertyStyles.room_list_group}`}>
                <div
                  className={`col-lg-4 col-4 ${ViewPropertyStyles.room_list}`}
                >
                  <div className={ViewPropertyStyles.name}>ROOM(S)</div>
                  <div className={ViewPropertyStyles.value}>
                    {property?.roomNumber}
                  </div>
                </div>
                <div
                  className={`col-lg-4 col-4 ${ViewPropertyStyles.room_list}`}
                >
                  <div className={ViewPropertyStyles.name}>TERRACE</div>
                  <div className={ViewPropertyStyles.value}>3</div>
                </div>
                <div
                  className={`col-lg-4 col-4 ${ViewPropertyStyles.room_list}`}
                >
                  <div className={ViewPropertyStyles.name}>Balcony</div>
                  <div className={ViewPropertyStyles.value}>
                    {property?.balconyNumber}
                  </div>
                </div>
                <div
                  className={`col-lg-4 col-4 ${ViewPropertyStyles.room_list}`}
                >
                  <div className={ViewPropertyStyles.name}>Bathroom(s)</div>
                  <div className={ViewPropertyStyles.value}>
                    {property?.bathRoomNumber}
                  </div>
                </div>
                <div
                  className={`col-lg-4 col-4 ${ViewPropertyStyles.room_list}`}
                >
                  <div className={ViewPropertyStyles.name}>FLOORS</div>
                  <div className={ViewPropertyStyles.value}>
                    {property?.floorNumber}
                  </div>
                </div>
                <div
                  className={`col-lg-4 col-4 ${ViewPropertyStyles.room_list}`}
                >
                  <div className={ViewPropertyStyles.name}>Lounge</div>
                  <div className={ViewPropertyStyles.value}>
                    {property?.loungeNumber}
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-lg-6`}>
              <div className={ViewPropertyStyles.text_section}>
                <h5 className={ViewPropertyStyles.title}>PROPERTY SUMMARY</h5>
                <p className={ViewPropertyStyles.details}>
                  {property?.summary}
                </p>
              </div>
            </div>
          </div>

          <section className={ViewPropertyStyles.photo_gallery_section}>
            <div className={`main_title ${ViewPropertyStyles.photo_title}`}>
              Photo gallery
            </div>
            <div className={ViewPropertyStyles.gallery_container}>
              <h4 className={ViewPropertyStyles.title}>Interior</h4>

              <div className={`mh_image_swiper_container`}>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={17}
                  navigation={false}
                  modules={[Autoplay]}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 17,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 17,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 17,
                    },
                  }}
                  className="mySwiper"
                >
                  {property &&
                    property.interiorImage.data.map(({ attributes }, index) => (
                      <SwiperSlide>
                        <img
                          src={`${urlConfig.BASE_URL}${attributes.url}`}
                          className="img-fluid"
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>

            <div className={ViewPropertyStyles.gallery_container}>
              <h4 className={ViewPropertyStyles.title}>Exterior</h4>

              <div className={`mh_image_swiper_container`}>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={17}
                  modules={[Autoplay]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  navigation={false}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 17,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 17,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 17,
                    },
                  }}
                  className="mySwiper"
                >
                  {property &&
                    property.exteriorImage.data.map(({ attributes }, index) => (
                      <SwiperSlide>
                        <img
                          src={`${urlConfig.BASE_URL}${attributes.url}`}
                          className="img-fluid"
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section
        className={`mh_cream_n_masked ${ViewPropertyStyles.other_properties_section} `}
      >
        <div className="container">
          <div className={ViewPropertyStyles.gallery_container}>
            <h4 className={`${ViewPropertyStyles.title} mb-4`}>
              OTHER PROPERTIES
            </h4>

            <div className="row">
              {allProperties &&
                allProperties.slice(0, 3).map(({ attributes }, index) => (
                  <div className="col-lg-4 mb-4" key={index}>
                    <PropertyCard
                      name={attributes.name}
                      location={attributes.location}
                      photo={`${urlConfig.BASE_URL}${attributes.previewImage.data.attributes.url}`}
                      slug={attributes.slug}
                      id={index}
                    />
                  </div>
                ))}
            </div>

            <div className="d-flex justify-content-center mt-5 mb-3">
              <button className="btn mh_primary_b_btn">Contact us</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewProperty;
