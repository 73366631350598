import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/home/home";
import Capital from "../pages/capital/capital";
import About from "../pages/about/about";
import Properties from "../pages/properties/properties";
import ContactUs from "../pages/contactUs/contactUs";
import ViewProperty from "../pages/viewProperty/viewProperty";
import { Footer } from "../components/layout/footer/footer";

export const MainRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/properties/:slug" element={<ViewProperty />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/mosun-capital" element={<Capital />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};
