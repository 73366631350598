import TestimonyCardStyles from "./testimonyCard.module.css";
import AvatarImg from "../../assets/images/newavatar.png";

export const TestimonyCard = () => {
  // Array of testimonials
  const testimonials = [
    {
      name: "Rabiu Kareem",
      testimonial:
        "We have had great success getting the property we want at a price that worked.",
      address: "Director, Watergate Luxury ",
    },
    {
      name: "Babatunde Adedeji",
      testimonial:
      "They are competent and genuine people and I am super impressed with them.",
      address: "CEO, BA Luxury Homes",
    },
    {
      name: "Mrs. Chidinma Udo",
      testimonial:
      "We were so fortunate to have found Mosun Homes when we moved to Lagos. As our Realtor, they helped us find the right house in the right neighborhood for the right price. They were patient as we inspect several homes and cautioned us about making unreasonable offers when we fell too quickly for overpriced homes. In short, they were always on our side working to make our house purchase as simple and successful as possible. The best part about working with Mosun Homes was that they always focus on answering our questions, giving us good advice, and finding homes that met our needs than they were on closing a deal. We would recommend them to anyone.",
      address: "Retired Civil servant",
    },
    {
      name: "Babatunde Rufai",
      testimonial:
      "Partnership as never been more easy and stress free. With a keen attention to management and professional reporting, we only look forward to more partnerships.",
      address: "CEO, Ruffy Investments",
    },
  ];

  return (
    <>
      {testimonials.map((testimonial, index ) => (
        <div className={TestimonyCardStyles.card_section} key={index}>
          <div className={TestimonyCardStyles.avatar_holder}>
            <img src={AvatarImg} alt="" className="img-fluid" />
          </div>

          <div className={TestimonyCardStyles.text_section}>
            <h4 className={TestimonyCardStyles.title}>{testimonial.name}</h4>
            <p className={TestimonyCardStyles.details}>{testimonial.testimonial}</p>
            <div className={TestimonyCardStyles.property_address}>
              {testimonial.address}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
