import teamStyles from "./teamCard.module.css";
import { ImageRB } from "../imageRB/imageRB";
import React, { useRef } from "react";
import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export const TeamCard = ({ name, title, details, image }) => {
  const detailRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(true);

  const handleSeeMore = () => {
    setIsTruncated(!isTruncated);
  };

  const truncateText = (details) => {
    let wordLength = details.length - 1;

    if (isTruncated) {
      wordLength = 214;
    }
    return `${details.substring(0, wordLength)}`;
  };

  return (
    <div className={teamStyles.card}>
      <ImageRB
        styleClass={"w-100"}
        imgSrc={image}
        imgAlt={`${name}, ${title} at Muson Homes & Realtors`}
      />
      <div className={teamStyles.title}>
        {name}
        <span> {title} </span>
      </div>
      {details && (
        //  <TransitionGroup>
        //   <CSSTransition
        //     key={isTruncated}
        //     timeout={1000}
        //     classNames={teamStyles.details_animate}
        //   >

        //   </CSSTransition>
        //  </TransitionGroup>

        <p ref={detailRef} className={teamStyles.details}>
          {truncateText(details)}{" "}
          <span onClick={handleSeeMore}>
            See {isTruncated ? "more" : "less"}
          </span>
        </p>
      )}
    </div>
  );
};
