import contactStyles from "./contactUs.module.css";
import { NavBar } from "../../components/layout/navbar/navbar";

const ContactUs = () => {
  return (
    <>
      <header className={contactStyles.hero_section}>
        <NavBar />

        <div className="container">
          <div className={contactStyles.text_section}>
            <h3 className={contactStyles.title}>Talk to our Support team</h3>
            <p className={contactStyles.details}>
              We'll quickly respond to your requests and find the right
              investment for you
            </p>
          </div>
        </div>
      </header>

      <section className={`${contactStyles.form_section} mh_cream_n_masked`}>
        <div className="container">
          <form className={`${contactStyles.form_container} contact_form`}>
            <div className="form-group mb-4">
              <input
                type={"text"}
                className="form-control"
                placeholder="Full name"
              />
            </div>
            <div className="form-group mb-4">
              <input
                type={"email"}
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div className="form-group mb-4">
              <input
                type={"text"}
                className="form-control"
                placeholder="Subject"
              />
            </div>

            <div className="form-group mb-4">
              <textarea
                className="form-control"
                placeholder="Subject"
              ></textarea>
            </div>

            <button className="btn mh_secondary_btn">Send</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
