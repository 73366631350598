import { ImageRB } from "../imageRB/imageRB";
import propertyStyles from "./propertyCard.module.css";
import { useNavigate } from "react-router-dom";

export const PropertyCard = ({ id, name, location, photo, slug }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/properties/${slug}`)}
      className={propertyStyles.property_card}
      //   style={{ backgroundImage: `url(${photo})` }}
    >
      <img
        src={photo}
        alt={`Mosun Property - ${name} at ${location}`}
        className={`${propertyStyles.property_img} img-fluid`}
      />
      <div className={propertyStyles.overlay}></div>

      <div className={propertyStyles.text_section}>
        <h5 className={propertyStyles.title}>{name}</h5>
        <p className={`${propertyStyles.details} mb-0`}>{location}</p>
      </div>
    </div>
  );
};
