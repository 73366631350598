import React, { useRef, useEffect } from "react";
import SplashScreenStyles from "./splash.module.css";

export const SplashScreen = () => {
  const mySplash = useRef(null);

  useEffect(() => {
    if (mySplash.current) {
      window.setTimeout(() => {
        mySplash.current.classList.add(SplashScreenStyles.close_splash);
      }, 7000);
    }
  }, [mySplash]);

  return (
    <div ref={mySplash} className={SplashScreenStyles.splash_container}>
      <div className={SplashScreenStyles.splash_overlay}></div>
      <div className={SplashScreenStyles.breathe_container}>
        <svg
          className={SplashScreenStyles.logo}
          width="82"
          height="80"
          viewBox="0 0 82 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M48.7935 17.1449L56.1781 21.7294H77.4263V25.8145L67.9244 31.0503L52.9038 21.7294H52.9795L45.5874 17.1449H45.5192L17.8906 0L0 9.837V65.0881H40.6577L33.2822 60.5127H4.56616V56.1984L17.7482 48.9497L33.0475 58.4482H33.0566L36.3808 60.5127H36.3899L43.7564 65.0881L45.5616 66.213L67.7896 80L82 72.1835V17.1449H48.7935ZM77.4263 54.9217L67.9275 60.1484L17.886 29.0966L4.56465 36.4257V27.1003L17.7467 19.8471L67.7881 50.9004L77.4263 45.5978V54.9217ZM17.7467 5.29498L67.7881 36.3468L77.4248 31.0427V40.3712L67.9259 45.5978L17.886 14.546L4.56465 21.8752V12.5513L17.7467 5.29498ZM67.9244 74.6974L17.8906 43.6456L4.56616 50.9793V41.6509L17.7482 34.3976L67.7881 65.4494L77.4263 60.1469V69.4708L67.9244 74.6974Z"
            fill="#EA9C22"
          />
        </svg>
      </div>
    </div>
  );
};
