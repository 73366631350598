import { MainRoutes } from "./routes/mainRoutes";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const App = () => {
  return (
    <>
      <MainRoutes />
    </>
  );
};

export default App;
