import footerStyles from "./footer.module.css";
import MHFullLogo from "../../../assets/images/logo/MH-full-logo-centeered.svg";
import { Link, useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className={footerStyles.footer_container}>
      <div className="container">
        <div className={footerStyles.footer_logo_links}>
          <img
            src={MHFullLogo}
            alt="Mosun Homes &ampo; Realtor footer logo"
            className="img-fluid"
          />

          <ul className={footerStyles.footer_links}>
            <li>
              <Link to="/"> Home</Link>
            </li>
            <li>
              <Link to="/about-us"> About us</Link>
            </li>
            <li>
              <Link to="/properties"> Properties</Link>
            </li>
            <li>
              <Link to="/mosun-capital">Mosun Capital</Link>
            </li>
          </ul>
        </div>

        <div className={footerStyles.contact_section}>
          <p className={`${footerStyles.address}`}>
            4th floor, Charis Ultimo Building, 42/44 Kunsela Road, Lekki
            peninsula II, 106104 Ikate Lagos
          </p>

          <button
            className="btn mh_primary_w_btn"
            onClick={() => navigate("/contact-us")}
          >
            Contact us
          </button>
        </div>

        <div className={footerStyles.footer_note}>
          <div className={footerStyles.copyright}>
            Copyright 2023. Mosun Homes &amp; Realtors. All rights reserved
          </div>

          <div className={footerStyles.contact_links}>
            <a>www.mosunhomes-realtors.com</a>
            <a>+234 - 913 - 626 - 4960</a>
            <a>Legal</a>
          </div>
        </div>
      </div>
    </footer>
  );
};
